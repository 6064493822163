<script setup>
import { computed, onMounted, ref } from 'vue'
import debounce from 'lodash/debounce'

const props = defineProps({
  modelValue: String,
  debounceTime: Number,
})

const emits = defineEmits(['update:modelValue'])

const input = ref(null)

onMounted(() => {
  if (input.value.hasAttribute('autofocus')) {
    input.value.focus()
  }
})

defineExpose({ focus: () => input.value.focus() })

const value = computed({
  get: () => props.modelValue,
  set: (value) =>
    props.debounceTime
      ? debounce((value) => {
          emits('update:modelValue', value)
        }, props.debounceTime)
      : emits('update:modelValue', value),
})
</script>

<template>
  <input
    ref="input"
    class="border-black rounded-md shadow-sm focus:border-2 focus:ring focus:ring-yellow-500"
    :value="value"
    @input="value = $event.target.value"
  />
</template>
